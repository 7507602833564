import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { Provider } from "react-redux";
import store from "./store";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_fr from "./assets/translations/fr/common.json";
import common_en from "./assets/translations/en/common.json";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./components/private";
import Home from "../src/pages/Home/";
import Agenda from "../src/pages/Agenda";
import More from "../src/pages/More";
import Profile from "../src/pages/Profile";
import Myrocketprogram from "../src/pages/myRocketProgram";
import Members from "../src/pages/Members";
import Workshop from "../src/pages/Workshop";
import Thread from "../src/pages/Thread";
import Help from "../src/pages/Help";
import Login from "../src/pages/Login";
import Register from "../src/pages/Register";

i18next.init({
  lng: "fr",
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
  },
  interpolation: { escapeValue: false },
});

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded)); // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser()); // Redirect to login
    window.location.href = "./login";
  }
}

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <Router>
          <Switch>
            <PrivateRoute exact path="/" component={Home}></PrivateRoute>
            <PrivateRoute
              exact
              path="/agenda"
              component={Agenda}
            ></PrivateRoute>
            <PrivateRoute exact path="/more" component={More}></PrivateRoute>
            <PrivateRoute
              exact
              path="/profile"
              component={Profile}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/myrocketprogram"
              component={Myrocketprogram}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/members"
              component={Members}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/workshop"
              component={Workshop}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/thread"
              component={Thread}
            ></PrivateRoute>
            <PrivateRoute exact path="/help" component={Help}></PrivateRoute>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React from "react";
import "./style.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

import Fusee from "../../assets/images/fusee.png";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      window.location.href = "./";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      window.location.href = "./"; // push user to dashboard when they login
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="loginBody">
        <div className="loginTitle">ROCKET PROGRAM</div>
        <div className="loginSubtitle">by Nextra Partners</div>
        <form noValidate onSubmit={this.onSubmit}>
          <div className="loginInputs">
            <input
              placeholder="Email"
              className="loginInput"
              onChange={this.onChange.bind(this)}
              value={this.state.email}
              error={errors.email}
              id="email"
              type="email"
            />
            <span className="red-text">
              {errors.email}
              {errors.emailnotfound}
            </span>
            <input
              placeholder="Password"
              className="loginInput"
              onChange={this.onChange.bind(this)}
              value={this.state.password}
              error={errors.password}
              id="password"
              type="password"
            />
            <span className="red-text">
              {errors.password}
              {errors.passwordincorrect}
            </span>
            <div className="loginFrgtpwd">mot de passe oublié ?</div>
            <button type="submit" className="loginButton">
              Connexion
            </button>
          </div>
        </form>
        <img src={Fusee} alt="Fusee" className="loginFusee" />
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);

import React from "react";

// import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import { useHistory } from "react-router-dom";

import HomeIcon from "@material-ui/icons/Home";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  label: {
    fontFamily: "Gotham Bold",
  },
});

export default function BotNav() {
  // const { t } = useTranslation("common");
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        // label={t("bottomNav.home")}
        icon={<HomeIcon />}
        className={classes.label}
        onClick={(_) => {
          history.push("/");
        }}
      />
      <BottomNavigationAction
        // label={t("bottomNav.agenda")}
        icon={<DateRangeIcon />}
        className={classes.label}
        onClick={(_) => {
          history.push("/agenda");
        }}
      />
      <BottomNavigationAction
        // label={t("bottomNav.more")}
        className={classes.label}
        icon={<MoreHorizIcon />}
        onClick={(_) => {
          history.push("/more");
        }}
      />
    </BottomNavigation>
  );
}

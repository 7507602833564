import React from "react";
import "./style.css";

import attachments from "./attachments";

import GetAppIcon from "@material-ui/icons/GetApp";
import BotNav from "../../components/BottomNav/index";

import { withTranslation } from "react-i18next";
import axios from "axios";

import Fusee from "../../assets/images/fusee.png";
const config = require("../../config");

class Workshop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    axios
      .get(config.api.invokeUrl + "/notes/find")
      .then((response) => {
        const val = response.data.content;
        this.setState({
          content: val,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    this.setState({ content: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const notes = {
      content: this.state.content,
    };

    axios
      .post(config.api.invokeUrl + "/notes/update", notes)
      .then((res) => console.log(res.data));
    window.location.reload(false);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="workshopHeader">
          <div className="workshopTitle">{t("workshop.title")}</div>
          <div className="nextrastroContainer">
            <img src={Fusee} alt="Fusee" className="nextrastro" />
          </div>
        </div>

        <div className="workshopEvent">
          <div>Atelier 6</div>
          <div>Data Gouvernance</div>
          <div>02/09/2021</div>
        </div>

        <div className="attachment">
          <GetAppIcon className="downIcon" />
          <div className="attachmentName" onClick={attachments}>
            Atelier 6 - Data Gouvernance - 21032021.pdf
          </div>
        </div>

        <div className="attachment">
          <GetAppIcon className="downIcon" />
          <div className="attachmentName">
            Atelier 6 - Data Gouvernance - 21032021.xls
          </div>
        </div>

        <div className="noteContainer">
          <form onSubmit={this.handleSubmit}>
            <textarea
              className="noteTaker"
              value={this.state.content}
              onChange={this.handleChange}
              placeholder="Ceci est un bloc-note"
            />

            <div className="saveContainer">
              <input className="saveButton" type="submit" value="SAUVEGARDER" />
            </div>
          </form>
        </div>
        <BotNav />
      </>
    );
  }
}

export default withTranslation("common")(Workshop);

import AWS from "aws-sdk";
import downloadjs from "downloadjs";

export default function attachments() {
  const ACCESS_KEY_ID = "AKIAXUA626BHV5EZLWX6";
  const SECRET_ACCESS_KEY = "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";
  const BUCKET_NAME = "rocket-program";

  var s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
    endpoint: new AWS.Endpoint("https://s3.eu-west-3.amazonaws.com"),
  });

  var params = {
    Key: "test.txt",
    Bucket: BUCKET_NAME,
  };

  s3.getObject(params, function (err, data) {
    if (err) {
      throw err;
    }
    downloadjs(data.Body, params.Key);
    console.log("File downloaded");
  });
}

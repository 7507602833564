import React from "react";
import "./style.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import BotNav from "../../components/BottomNav/index";

import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import FlagIcon from "@material-ui/icons/Flag";
import SearchIcon from "@material-ui/icons/Search";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import HelpIcon from "@material-ui/icons/Help";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

class More extends React.Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { user } = this.props.auth;

    return (
      <div>
        <div className="moreHeader">
          <div>{user.firstname}</div>
          <div>{user.name}</div>
          <div className="moreAvatar">
            <Avatar>
              <PersonIcon />
            </Avatar>
          </div>
        </div>

        <div className="moreMain">
          <div className="moreTitles">Plus</div>

          <div
            className="moreContainer"
            onClick={(_) => {
              window.location.href = "./myrocketprogram";
            }}
          >
            <FlagIcon className="moreFlag" fontSize="large" />
            <div className="moreSections">Mon Rocket Program</div>
          </div>

          <div
            className="moreContainer"
            onClick={(_) => {
              window.location.href = "./notifications";
            }}
          >
            <NotificationsIcon className="moreBell" fontSize="large" />
            <div className="moreSections">Notifications</div>
          </div>

          <div
            className="moreContainer"
            onClick={(_) => {
              window.location.href = "./thread";
            }}
          >
            <QuestionAnswerIcon className="moreBubble" fontSize="large" />
            <div className="moreSections">Fil de discussion</div>
          </div>

          <a className="moreContainer" href="https://www.nextra-partners.com/">
            <SearchIcon className="moreNP" fontSize="large" />
            <div className="moreSections">Nextra Partners</div>
          </a>

          <div className="moreContainer">
            <BookmarkIcon className="moreArticles" fontSize="large" />
            <div className="moreSections">Articles</div>
          </div>

          <div className="moreTitles">Paramètres</div>

          <div
            className="moreContainer"
            onClick={(_) => {
              window.location.href = "./profile";
            }}
          >
            <PersonIcon className="moreSettings" fontSize="large" />
            <div className="moreSections">Profil</div>
          </div>

          <div
            className="moreContainer"
            onClick={(_) => {
              window.location.href = "./help";
            }}
          >
            <HelpIcon className="moreSettings" fontSize="large" />
            <div className="moreSections">Aide</div>
          </div>
          <div onClick={this.onLogoutClick} className="moreContainer">
            <ExitToAppIcon className="moreSettings" fontSize="large" />
            <div className="moreSections">Déconnexion</div>
          </div>
        </div>
        <BotNav />
      </div>
    );
  }
}

More.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(More);

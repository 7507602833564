import React from "react";
import "./style.css";

import Fusee from "../../assets/images/fusee.png";
import Astronaute from "../../assets/images/astronaute.png";
import BotNav from "../../components/BottomNav/index";

import { useTranslation } from "react-i18next";

export default function Help() {
  const { t } = useTranslation("common");
  return (
    <>
      <div className="helpHeader">
        <div className="helpTitle">{t("help.title")}</div>
        <div className="nextrastroContainer">
          <img src={Fusee} alt="Fusee" className="nextrastro" />
        </div>
      </div>
      <div className="helpMain">
        <div className="helpText">
          {t("help.text1")}
          <br />
          {t("help.text2")}
          <br />
          rocket-program@nextra-partners.com
        </div>
        <img src={Astronaute} alt="Fusee" className="helpAstro" />
      </div>
      <BotNav />
    </>
  );
}

import React, { Component } from "react";
import "./style.css";

import Calendar from "react-calendar";

import Astronaute from "../../assets/images/astronaute.png";

import BotNav from "../../components/BottomNav/index";

// export default function Agenda() {
//   const [value, setValue] = useState(new Date());

//   return (
//     <div>
//       <div className="agendaHeader">
//         <div className="agendaTitle">Agenda</div>
//         <div className="nextrastroContainer">
//           <img src={Astronaute} alt="Nextrastro" className="nextrastro" />
//         </div>
//       </div>
//       <Calendar onClickDay={() => console.log(value)} value={value} />
//       {/* <div>Vous avez cliqué sur {value}</div> */}
//     </div>
//   );
// }

export default class Agenda extends Component {
  constructor() {
    super();
    this.state = {
      date: new Date(),
    };
  }

  onChange = (date) => {
    this.setState({ selectedDate: date });
    console.log(date);
  };

  render() {
    return (
      <div>
        <div className="agendaHeader">
          <div className="agendaTitle">Agenda</div>
          <div className="nextrastroContainer">
            <img src={Astronaute} alt="Nextrastro" className="nextrastro" />
          </div>
        </div>
        <Calendar value={this.state.date} onClickDay={this.onChange} />

        <div className="eventTitle">Prochains Évenements</div>

        <div className="event">
          <div className="eventContainer">
            <div className="eventLeft">
              <div>Atelier 4</div>
              <div>Business Intelligence</div>
              <div className="eventDate">21/05/2021</div>
            </div>
            <div className="eventRight">
              <div className="startTime">14:00</div>
              <div className="endTime">18:00</div>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="eventContainer">
            <div className="eventLeft">
              <div>End Party</div>
              <div>Social</div>
              <div className="eventDate">21/05/2021</div>
            </div>
            <div className="eventRight">
              <div className="startTime">19:00</div>
              <div className="endTime">22:00</div>
            </div>
          </div>
        </div>
        <BotNav />
      </div>
    );
  }
}

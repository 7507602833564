import React from "react";
import "./style.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import Astronaute from "../../assets/images/astronaute.png";
import BotNav from "../../components/BottomNav/index";

import PersonIcon from "@material-ui/icons/Person";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ReplyIcon from "@material-ui/icons/Reply";

import axios from "axios";
const config = require("../../config");

class Thread extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      form: false,
      date: new Date().toLocaleString(),
      user: [],
      content: "",
      answer: false,
      answercontent: "",
    };
  }

  componentDidMount = () => {
    const { user } = this.props.auth;
    this.setState({ user: user });
    this.getThread();
  };

  getThread = () => {
    axios
      .get(config.api.invokeUrl + "/messages/list")
      .then((res) => {
        const messages = res.data;
        this.setState({ messages: messages });
      })
      .catch(() => {
        alert("Error");
      });
  };

  displayThread = (messages) => {
    return messages.map((message, index) => (
      <div key={index} className="threadMessage">
        <div className="messageLeft" />
        <div className="messageContent">
          <div className="messageHeader">
            <PersonIcon />
            <div className="messageWrapper">
              <div className="messageUsername">{message.user}</div>
              <div className="messageDate">{message.date}</div>
            </div>
          </div>
          {message.answer ? (
            <div className="messageAnswer">{message.answercontent}</div>
          ) : null}
          <div className="messageContent">{message.message}</div>
          <div
            className="answerContainer"
            onClick={this.openReplyForm.bind(this, message.message)}
          >
            <ReplyIcon />
            <div className="answerText">Répondre</div>
          </div>
        </div>
      </div>
    ));
  };

  openReplyForm = (message) => {
    this.setState({ form: true, answer: true, answercontent: message });
  };

  openForm = () => {
    this.setState({ form: true });
  };

  closeForm = () => {
    this.setState({ form: false });
  };

  handleSubmit(event) {
    event.preventDefault();
    const message = {
      user: this.state.user.firstname + " " + this.state.user.name,
      date: this.state.date,
      message: this.state.content,
      answer: this.state.answer,
      answercontent: this.state.answercontent,
    };

    axios
      .post(config.api.invokeUrl + "/messages/add", message)
      .then((res) => console.log(res.data));

    this.closeForm();
    window.location.reload(false);
  }

  handleChange(event) {
    this.setState({ content: event.target.value });
  }

  render() {
    const styles = {
      largeIcon: {
        width: 60,
        height: 60,
      },
    };
    return (
      <>
        <div className="threadHeader">
          <div className="threadTitle">Fil de discussion</div>
          <div className="nextrastroContainer">
            <img src={Astronaute} alt="Nextrastro" className="nextrastro" />
          </div>
        </div>

        <div className="threadMessages">
          {this.displayThread(this.state.messages)}
        </div>

        <div>
          <AddCircleIcon
            style={styles.largeIcon}
            className="plusIcon"
            onClick={this.openForm}
          />
        </div>

        {this.state.form ? (
          <div className="newMessage">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <textarea
                className="inputMessage"
                value={this.state.content}
                onChange={this.handleChange.bind(this)}
              />
              <div className="formButtons">
                <button
                  className="closeButton"
                  type="button"
                  onClick={this.closeForm}
                >
                  FERMER
                </button>
                <button className="openButton" type="submit">
                  POSTER
                </button>
              </div>
            </form>
          </div>
        ) : null}
        <BotNav />
      </>
    );
  }
}

Thread.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Thread);

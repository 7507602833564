import React from "react";
import "./style.css";

import { useHistory } from "react-router-dom";

import Astronaute from "../../assets/images/astronaute.png";
import BotNav from "../../components/BottomNav/index";

import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation("common");
  const history = useHistory();
  return (
    <>
      <div className="homeHeader">
        <div className="homeTitle">{t("home.title")}</div>
        <div className="nextrastroContainer">
          <img src={Astronaute} alt="Nextrastro" className="nextrastro" />
        </div>
      </div>
      <div className="homeContent">
        <div className="homeContenttitle">{t("home.loading")}</div>
        <div
          className="progressBarcontainer"
          onClick={(_) => {
            history.push("/myrocketprogram");
          }}
        >
          <div className="progressBarleft">80%</div>
          <div className="progressBarright"></div>
        </div>
        <div className="homeContentsubtitle">{t("home.nextevent")}</div>
        <div
          className="homeEvent"
          onClick={(_) => {
            history.push("/workshop");
          }}
        >
          <div>Atelier 4</div>
          <div>Business Intelligence</div>
          <div>02/09/2021</div>
        </div>
        <div className="homeContentsubtitle">{t("home.lastevent")}</div>
        <div
          className="homeEvent"
          onClick={(_) => {
            history.push("/workshop");
          }}
        >
          <div>Atelier 3</div>
          <div>Data Quality</div>
          <div>01/09/2021</div>
        </div>
        <div
          className="homeEvent"
          onClick={(_) => {
            history.push("/workshop");
          }}
        >
          <div>Atelier 2</div>
          <div>Artificial Intelligence</div>
          <div>01/09/2021</div>
        </div>
        <div
          className="homeEvent"
          onClick={(_) => {
            history.push("/workshop");
          }}
        >
          <div>Atelier 1</div>
          <div>Company Data Analysis</div>
          <div>01/09/2021</div>
        </div>
      </div>
      <BotNav />
    </>
  );
}

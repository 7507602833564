import React from "react";
import "./style.css";

import Logo from "../../assets/images/LI-In-Bug.png";

import BotNav from "../../components/BottomNav/index";

import axios from "axios";
const config = require("../../config");

export default class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
    };
  }

  componentDidMount = () => {
    this.getMembers();
  };

  getMembers = () => {
    axios
      .get(config.api.invokeUrl + "/users/list")
      .then((res) => {
        const members = res.data;
        this.setState({ members: members });
      })
      .catch(() => {
        alert("Error");
      });
  };

  displayMembers = (members) => {
    return members.map((members, index) => (
      <div key={index} className="membersCard">
        <div className="membersName">
          {members.firstname} {members.name}
        </div>
        <div className="membersCompany">{members.company}</div>
        {members.linkedin ? (
          <a href={members.linkedin} className="membersLI">
            <img src={Logo} alt="liLogo" className="linkedinLogo" />
          </a>
        ) : null}
      </div>
    ));
  };

  render() {
    return (
      <div>
        <div className="membersHeader">
          <div className="membersTitle">Membres</div>
        </div>
        <div>{this.displayMembers(this.state.members)}</div>
        <BotNav />
      </div>
    );
  }
}

import React from "react";
import "./style.css";

import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import BotNav from "../../components/BottomNav/index";

import Fusee from "../../assets/images/fusee.png";

export default function Myrocketprogram() {
  const { t } = useTranslation("common");
  const history = useHistory();
  return (
    <>
      <div className="rocketProgramHeader">
        <div className="rocketProgramTitle">{t("rocketprogram.title")}</div>
        <div className="nextrastroContainer">
          <img src={Fusee} alt="Fusee" className="nextrastro" />
        </div>
      </div>
      <div className="rocketProgramSubtitle">
        Ceci est un Rocket Program
        <div
          className="members"
          onClick={(_) => {
            history.push("/members");
          }}
        >
          6 membres
        </div>
        <div
          className="linkThread"
          onClick={(_) => {
            history.push("/thread");
          }}
        >
          {t("thread.title")}
        </div>
      </div>
      <div
        className="rpEventnext"
        onClick={(_) => {
          history.push("/workshop");
        }}
      >
        <div>Atelier 6</div>
        <div>Data Gouvernance</div>
        <div>02/09/2021</div>
      </div>
      <div
        className="rpEventnext"
        onClick={(_) => {
          history.push("/workshop");
        }}
      >
        <div>Atelier 5</div>
        <div>Data Architecture</div>
        <div>02/09/2021</div>
      </div>
      <div
        className="rpEventnext"
        onClick={(_) => {
          history.push("/workshop");
        }}
      >
        <div>Atelier 4</div>
        <div>Business Intelligence</div>
        <div>02/09/2021</div>
      </div>
      <div
        className="rpEvent"
        onClick={(_) => {
          history.push("/workshop");
        }}
      >
        <div>Atelier 3</div>
        <div>Data Quality</div>
        <div>02/09/2021</div>
      </div>
      <div
        className="rpEvent"
        onClick={(_) => {
          history.push("/workshop");
        }}
      >
        <div>Atelier 2</div>
        <div>Artificial Intelligence</div>
        <div>02/09/2021</div>
      </div>
      <div
        className="rpEvent"
        onClick={(_) => {
          history.push("/workshop");
        }}
      >
        <div>Atelier 1</div>
        <div>Company Data Analysis</div>
        <div>02/09/2021</div>
      </div>
      <BotNav />
    </>
  );
}

import React from "react";
import "./style.css";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";

import Fusee from "../../assets/images/fusee.png";

class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      name: "",
      email: "",
      password: "",
      password2: "",
      company: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      window.location.href = "./";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      firstname: this.state.firstname,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      company: this.state.company,
    };
    this.props.registerUser(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="registerBody">
        <div className="registerTitle">ROCKET PROGRAM</div>
        <div className="registerSubtitle">by Nextra Partners</div>
        {/* <div className="registerDisclaimer">{t("register.disclaimer")}</div> */}
        <form noValidate onSubmit={this.onSubmit}>
          <div className="registerInputs">
            <input
              placeholder="Prénom"
              className="registerInput"
              onChange={this.onChange}
              value={this.state.firstname}
              error={errors.firstname}
              id="firstname"
              type="text"
            />
            <span className="red-text">{errors.firstname}</span>
            <input
              placeholder="Nom"
              className="registerInput"
              onChange={this.onChange}
              value={this.state.name}
              error={errors.name}
              id="name"
              type="text"
            />
            <span className="red-text">{errors.name}</span>
            <input
              placeholder="Courriel"
              className="registerInput"
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="email"
              type="email"
            />
            <span className="red-text">
              {errors.email}
              {errors.unknown}
            </span>
            <input
              placeholder="Mot de passe"
              className="registerInput"
              onChange={this.onChange}
              value={this.state.password}
              error={errors.password}
              id="password"
              type="password"
            />
            <span className="red-text">{errors.password}</span>
            <input
              placeholder="Confirmation Mot de passe"
              className="registerInput"
              onChange={this.onChange}
              value={this.state.password2}
              error={errors.password2}
              id="password2"
              type="password"
            />
            <span className="red-text">{errors.password2}</span>
            <input
              placeholder="Entreprise"
              className="registerInput"
              onChange={this.onChange}
              value={this.state.company}
              error={errors.company}
              id="company"
              type="text"
            />
            <span className="red-text">{errors.company}</span>

            <button type="submit" className="registerButton">
              Inscription
            </button>
          </div>
          {/* <div className="registerLinkedIn"></div> */}
        </form>
        <div className="registerCgu">
          En confirmant votre inscription vous acceptez nos Termes & Conditions
        </div>
        <img src={Fusee} alt="Fusee" className="registerFusee" />
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));

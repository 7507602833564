import React from "react";
import "./style.css";

import { useTranslation } from "react-i18next";
import BotNav from "../../components/BottomNav/index";

import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import LanguageIcon from "@material-ui/icons/Language";

export default function Profile() {
  const { i18n, t } = useTranslation("common");
  return (
    <div className="profileWrapper">
      <div className="moreHeader">
        <div>Freitrich</div>
        <div>RATTE</div>
        <div className="moreAvatar">
          <Avatar>
            <PersonIcon />
          </Avatar>
        </div>
      </div>

      <div className="profileTitle">{t("settings.settings")}</div>

      <div className="profileContainer">
        <PersonIcon className="moreSettings" fontSize="large" />
        <div className="moreSections">{t("settings.picture")}</div>
      </div>

      <div className="profileContainer">
        <LockIcon className="moreSettings" fontSize="large" />
        <div className="moreSections">{t("settings.password")}</div>
      </div>

      <div className="profileContainerbis">
        <LanguageIcon className="moreSettings" fontSize="large" />
        <div className="profileSections">{t("settings.language")}</div>
      </div>

      <div className="profileButtons">
        <div className="langbuttonfr" onClick={() => i18n.changeLanguage("fr")}>
          Français
        </div>
        <div className="langbuttonen" onClick={() => i18n.changeLanguage("en")}>
          English
        </div>
      </div>

      <div className="profileLI">
        <div className="liLogo">in</div>
        <div>{t("settings.linkedin")}</div>
      </div>
      <BotNav />
    </div>
  );
}
